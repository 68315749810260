import https from "./https"

const marketingRule = {
  getMarketingRules(merchantId, params) {
    return https.get(`admin/merchants/${merchantId}/marketing-rules`, {params})
  },
  getMarketingRule(marketingRuleId) {
    return https.get(`admin/marketing-rules/${marketingRuleId}`)
  },
  updateMarketingRule(merchantId, marketingRuleId, params) {
    return https.put(`admin/merchants/${merchantId}/marketing-rules/${marketingRuleId}`, params)
  },
  createMarketingRule(merchantId, params) {
    return https.post(`admin/merchants/${merchantId}/marketing-rules`, params)
  },
  removeMarketingRule(marketingRuleId) {
    return https.delete(`admin/marketing-rules/${marketingRuleId}`)
  },
}

export default marketingRule
